// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-get-app-index-tsx": () => import("./../../../src/pages/get-app/index.tsx" /* webpackChunkName: "component---src-pages-get-app-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quick-start-index-tsx": () => import("./../../../src/pages/quick-start/index.tsx" /* webpackChunkName: "component---src-pages-quick-start-index-tsx" */),
  "component---src-pages-removal-index-tsx": () => import("./../../../src/pages/removal/index.tsx" /* webpackChunkName: "component---src-pages-removal-index-tsx" */)
}

